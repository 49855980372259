<template>
  <b-modal 
    id="itemsOutputModal" 
    title="Saída de produtos"
    hide-footer size="lg"
  >
    <section class="patient-container">
      <p>Paciente</p>
      <h5>{{ patient.name }}</h5>
      <b-container class="w-100 pl-2">
        <b-row class="w-100 d-flex justify-content-between">
          <p v-show="patient?.birthday">
            Nascimento: <span class="bold-600">{{ parseDate(patient?.birthday)}}</span>
          </p>
          <p v-show="patient?.cellphone">
            Celular: <span class="bold-600">{{ patient?.cellphone }}</span>
          </p>
          <p v-show="patient?.medical_record_number">
            Prontuário: 
            <span class="blue bold-600">
              {{ patient?.medical_record_number }}
            </span>
          </p>
        </b-row>
      </b-container>
    </section>

    <section>
      <b-container class="info w-100 p-4 mt-2">
        <b-row>
          <b-col cols="1" class="text-align-center" align-self="center">
            <Info class="icon info-icon" />
          </b-col>
          <b-col>
            <p class="bold-700">Adicione e gerencie produtos utilizados no procedimento</p>
            <p class="bold-600">
              Você pode selecionar os produtos que serão vendidos separadamente e não estão inclusos no valor do procedimento.
            </p>
          </b-col>
        </b-row>
      </b-container>
    </section>

    <section class="procedures mt-2 mb-2">
      <b-card v-for="(procedure,index) in surgeryProcedures" 
        :id="`procedure-${index}-card`" :key="index" class="procedure-card mb-2 mt-2" no-body
      > 
        <b-card-header class="header pt-3 pb-1" role="tab">
          <b-row class="justify-content-between">
            <h5>Procedimento {{ ++index }}</h5>
            <b-button v-b-toggle="`procedure-${index}`" variant="link" @click="rotateChevron">
              <ChevronDown class="chevron"/>
            </b-button>
          </b-row>
        </b-card-header>
        <b-collapse :id="`procedure-${index}`" visible :accordion="`procedure-${index}`" role="tabpanel">
          <b-container class="mt-1 mb-1">
            <b-row class="procedure-container">
              <ItemIconHandler :type="procedure?.item?.type" class="mr-2"/>
              <span>
                {{ parseProcedureType(procedure) }} |
              </span>
              <h5 class="mb-0 ml-1">
                {{ procedure?.item?.name}}
              </h5>
            </b-row>
          </b-container>

          <b-card-body class="card-body pt-0 pb-0">
            <b-table-simple class="table-eyecare mt-3">
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>Qtde.</th>
                  <th>Lote</th>
                  <th>Valor de venda</th>
                  <th></th>
                </tr>
              </thead>
              <tbody v-for="(item, index) in items.filter(item => item.surgery_information_procedure_id === procedure.id)" :key="index">
                <tr>
                  <td>
                    <strong>{{ item?.item?.barcode ?? item?.item?.code }}</strong>
                    - {{  item?.item?.product?.name ?? item?.item?.template?.name }}
                  </td>
                  <td>
                    <b-form-input
                      :value="item?.item?.quantity ?? 1"
                      @update="$set(item.item, 'quantity', $event)"
                      placeholder="1"
                      type="number"
                      min="1"
                      max="999"
                      class="quantity-input m-0"
                    />
                  </td>
                  <td class="batch-col">
                    {{ item?.item?.batch }} 
                  </td>
                  <td>
                    <span v-if="item?.item?.sale_price">
                      {{ parseNumberToMoney(item?.item?.sale_price*item?.item?.quantity) }}
                    </span>
                    <span v-else-if="item?.item?.kit_products">
                      {{ parseNumberToMoney(getKitTotalSale(item?.item)) }}
                    </span>
                  </td>
                  <td class="text-align-center">
                    <section class="options">
                      <MoreVertical class="more-icon" />
                      <div class="menu">
                        <b-button variant="link remove mb-0" @click="removeItem(item)">
                          Excluir
                        </b-button>
                      </div>
                    </section>
                  </td>
                </tr>
                <tr v-for="(kitProduct, index) in item?.item?.kit_products" :key="index" class="kit-products">
                  <td>
                    <ArrowUp class="arrow ml-5"/>
                    <strong>{{ kitProduct?.product?.barcode }}</strong>
                    - {{ kitProduct?.product?.name }}
                  </td>
                  <td>
                    <b-form-input
                      :value="kitProduct?.quantity ?? 1"
                      @update="kitProduct.quantity = $event"
                      placeholder="1"
                      type="number"
                      min="1"
                      max="999"
                      class="quantity-input m-0"
                    />
                  </td>
                  <td>{{ kitProduct?.batch?.name }}</td>
                  <td>
                    <span v-if="kitProduct?.selling_cost">
                      {{ 
                        parseNumberToMoney(
                          parseFloat(kitProduct?.selling_cost) * parseFloat(kitProduct?.quantity)
                        )
                      }}
                    </span>
                  </td>
                  <td>
                    <section class="options">
                      <MoreVertical class="more-icon" />
                      <div class="menu">
                        <b-button variant="link remove mb-0" @click="removeItemProduct(item)">
                          Excluir
                        </b-button>
                      </div>
                    </section>
                  </td>
                </tr>
              </tbody>
            </b-table-simple>
            <div class="w-100 d-flex justify-content-center" v-if="addSurgeryItem">
              <b-button
                class="wh-button add-product-button"
                variant="link"
                size="lg"
                @click="addProductTransactionToProcedure(procedure)"
              >
                Adicionar produto
              </b-button>
            </div>
          </b-card-body>
        </b-collapse>
      </b-card>
    </section>
    <b-container>
      <b-row class="border-top pt-3 pb-3">
        <b-col cols="6" class="text-align-start">
          Valor total de custo
        </b-col>
        <b-col cols="6" class="text-right">
          <span class="neutral-700-bold">
            {{ parseNumberToMoney(getTotalCost()) }}
          </span>
        </b-col>
      </b-row>
      <b-row class="border-top border-bottom pt-3 pb-3">
        <b-col cols="6" class="text-align-start">
          Valor total para venda
        </b-col>
        <b-col cols="6" class="text-right">
          <span class="neutral-700-bold">
            {{ parseNumberToMoney(getTotalSale()) }}
          </span>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" class="text-align-center mt-3">
          <b-button
            variant="primary"
            size="lg"
            @click="outputSurgeryProducts"
          >
            Efetuar saída
          </b-button>
        </b-col>
      </b-row>
    </b-container>

    <ProductTransactionModal
      identifier="warehouse-transaction-entry"
      type="ENTRANCE"
      @onSave="addProductTransaction"
    />
  </b-modal>
</template>

<script>
import { parseNumberToMoney } from '@/utils/moneyHelper'
import { parseDate } from '@/utils/dateHelper.js'
import { parseProcedureType } from '@/utils/procedure-helper.js'
import { getCurrentClinic } from '@/utils/localStorageManager.js'
export default {
  name: 'ItemsOutputModal',
  components: {
    Info: () => import('@/assets/icons/info-circle.svg'),
    ArrowUp: () => import('@/assets/icons/arrow-up2.svg'),
    ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
    MoreVertical: () => import('@/assets/icons/more-vertical.svg'),
    ItemIconHandler: () => import('@/components/Overview/ItemIconHandler.vue'),
    ProductTransactionModal: () => import('@/components/Warehouse/ProductTransactionModal'),
  },
  props: {
    items: { type: Array, default: () => [] },
    surgeryProcedures: { type: Array, required: true },
    patient: { type: Object, required: true},
    addSurgeryItem: Function,
  },
  data: () => ({
    selectedProcedure: null,  
    clinic: getCurrentClinic(), 
  }),
  methods: {
    parseDate,
    parseNumberToMoney,
    parseProcedureType(procedure) {
      const type = this.getSurgeryType(procedure)

      return parseProcedureType(type)
    },
    getCurrentClinic,
    getSurgeryType(procedure) {
      return procedure?.item?.type
    },
    rotateChevron($event) {
      const icon = $event.target.closest('button').querySelector('.chevron')
      icon.classList.toggle('rotate')
    },
    getPayloadProps(surgeryProcedure) {
      const props = {
        clinic_id: this.clinic.id,
        date: this.moment().toDate(),
        type: 'PROCEDURE',
        patient_id: this.patient.id,
        procedure_id: surgeryProcedure.clinicProcedure_id,
      }

      const productTransactions = this.items.filter(
        item => item.item_type.includes('WarehouseProductTransaction') && item.surgery_information_procedure_id === surgeryProcedure.id
      ).map(item => {
          return { ...item.item, type: 'OUTPUT', surgery_item_id: item.id }
      })

      const kitProductTransactions = this.items.filter(
        item => item.item_type.includes('Kit') && item.surgery_information_procedure_id === surgeryProcedure.id
      ).map(item => {
        return item.item.kit_products.map(kitProduct => {
          return {
            surgery_item_id: item.id,
            batch: kitProduct?.batch?.batch,
            expiration_date: this.moment(kitProduct?.expiration_date),
            manufacturing_date: null,
            product_id: kitProduct?.product_id,
            product: kitProduct?.product,
            quantity: kitProduct.quantity,
            purchase_price: kitProduct?.cost,
            type: 'OUTPUT',
            sale_price: kitProduct?.selling_cost,
          }
        })
      }).flat()

      props.transactions = [...productTransactions, ...kitProductTransactions]
      return props
    },
    async outputSurgeryProducts() {
      const isLoading = this.$loading.show()
      try {
        const payload = []
        this.surgeryProcedures.map(async surgeryProcedure => {
          payload.push(this.getPayloadProps(surgeryProcedure))
        })
        await this.api.outputSurgeryProcedureItems(payload)
        this.$toast.success('Saída de produtos realizada com sucesso')
        this.$bvModal.hide('itemsOutputModal')
        this.$emit('onSave')
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    addProductTransactionToProcedure(procedure) {
      this.selectedProcedure = procedure
      this.$bvModal.show('warehouse-transaction-entry')
    },
    async addProductTransaction(product) {
      const isLoading = this.$loading.show()
      try {
        const res = await this.api.createWarehouseEntry({
          date: this.moment().toDate(),
          transactions: [product],
          clinic_id: this.clinic.id
        })
        const productTransaction = {
          ...res.data.transactions[0],
          type: 'WAREHOUSE_PRODUCT_TRANSACTIONS', 
        }

        await this.addSurgeryItem(productTransaction, this.selectedProcedure.id)
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    removeItem(item) {
      this.items = this.items.filter(el => el.id !== item.id)
    },
    removeItemProduct(item) {
      item.item.kit_products = item.item.kit_products.filter(el => el.id !== item.id)
    },
    getKitTotalSale(item) {
      const sum = item.kit_products.reduce((sum, kitProduct) => {
        if (kitProduct.selling_cost) 
          return sum + parseFloat(kitProduct.selling_cost) * (kitProduct.quantity ?? 1)
        return sum
      }, 0)

      return sum * (item.quantity ?? 1)
    },
    getKitTotalCost(item) {
      const sum = item.kit_products.reduce((sum, kitProduct) => {
        if (kitProduct.cost) 
          return sum + parseFloat(kitProduct.cost) * (kitProduct.quantity ?? 1)
        return sum
      }, 0)

      return sum * (item.quantity ?? 1)
    },
    getTotalCost() {
      const sum = this.items.reduce((sum, item) => {
        if (item.item.sale_price) 
          return sum + parseFloat(item.item.purchase_price) * (item.item.quantity ?? 1)
        if (item.item.kit_products) 
          return sum + this.getKitTotalCost(item.item)
        return sum
      }, 0)

      return sum
    },
    getTotalSale() {
      const sum = this.items.reduce((sum, item) => {
        if (item.item.sale_price) 
          return sum + parseFloat(item.item.sale_price) * (item.item.quantity ?? 1)
        if (item.item.kit_products) 
          return sum + this.getKitTotalSale(item.item)
        return sum
      }, 0)

      return sum
    }
  }
}
</script>

<style lang="scss">
#itemsOutputModal {
  .icon {
    width: 24px;
    height: 24px;
  }

  p {
    color: var(--type-active);
  }

  .bold-600 { font-weight: 600; }
  .bold-700 { font-weight: 700; }

  .patient-container {
    h5 {
      font-size: 1.5rem;
      font-weight: 700;
      color: var(--dark-blue);
    }

    .blue {
      color: var(--blue-500);
      font-weight: 600;
    }
  }

  .procedures {
    width: 100%;
    max-height: 60vh;
    overflow: auto;
  }

  .procedure-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 10px;

    span {
      font-size: 1rem;
      font-weight: 600;
      color: var(--blue-500);
    }
  }

  .info {
    background: rgba(229, 249, 255, 1);
    border-radius: 8px;

    .info-icon {
      stroke: rgba(0, 136, 178, 1);
    }
  }

  .procedure-card {
    border: none;
    .header {
      background: var(--neutral-100); 

      h5 {
        font-size: 1rem;
        font-weight: 600;
        color: var(--neutral-700);
      }
    }
  }

  .table-eyecare {
    .arrow {
      transform: scale(2);
      margin-right: 20px;
    }

    td { 
      padding-left: 0 !important; 
    }
    .quantity-input { 
      width: 70px;
    }

    .batch-col {
      min-width: 50px;
    }

    .kit-products {
      background: var(--neutral-100);
      tr {
        display: flex;
        align-items: center;
      }
    }
  }

  .add-product-button {
    font-size: 1.1rem;
  }

  .options {
    cursor: pointer;
    width: 24px;
    height: 28px;
    position: relative;
    display: inline-block;

    .more-icon {
      width: 24px;
      height: 24px;
      stroke: var(--neutral-500);
      transition: all 0.5s;
      cursor: pointer;
    }

    .menu {
      width: 141px;
      position: absolute;
      top: 28px;
      right: 0;
      background: var(--neutral-000);
      border: 1px solid var(--neutral-200);
      box-sizing: border-box;
      box-shadow: 0px 4px 4px rgba(12, 29, 89, 0.1),
        0px 4px 10px -8px rgba(12, 29, 89, 0.2);
      border-radius: 8px;
      padding: 0;
      opacity: 0;
      transition: all 0.5s;
      flex-direction: column;
      align-items: start;
      display: none;

      .mtb-12 {
        margin: 12px 0;
      }

      .btn {
        font-weight: 600;
        width: 100%;

        &.remove {
          color: var(--states-error);
        }

        &.btn-link {
          text-align: left;
          padding: 12px 18px !important;
        }
      }
    }

    &:hover {
      .more-icon {
        transform: rotate(90deg);
      }

      .menu {
        opacity: 1;
        display: flex;
        z-index: 10;
      }
    }
  }

  .border-top { border-top: 1px solid var(--neutral-200); }
  .border-bottom { border-bottom: 1px solid var(--neutral-200); }

  .neutral-700-bold {
    color: var(--neutral-700);
    font-weight: 700;
  }

}
</style>

<style lang="scss" scoped>
::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-track {
  background: var(--neutral-000);
  border-radius: 0 0 8px 0;
}

::-webkit-scrollbar-thumb {
  background-color: var(--neutral-300);
  border-radius: 100px;
  border: 3px solid var(--neutral-000);
}

::-webkit-scrollbar-thumb:hover {
  cursor: pointer;
  background: var(--neutral-200);
}
</style>